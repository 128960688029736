import { debounce } from "lodash";
import { useContext, useEffect, useRef, useState } from "react";
import { Button } from "../../components/Button/index.tsx";
import Form from "../../components/Form/index.tsx";
import Tree from "../../components/Tree/Tree.tsx";
import { Node } from "../../types/Node.ts";
import nodes from "./nodesJson.tsx";
import "./styles.ts";
import { ContainerConfiguracoes } from "./styles.ts";

import { ReactComponent as Arrow } from "../../assets/newImages/icons/angle-down.svg";

import { useParams } from "react-router-dom";
import Swal from "sweetalert2";
import Dropdown from "../../components/Dropdowns/Dropdown.tsx";
import { FooterPaginas } from "../../components/FooterPaginas/FooterPaginas.tsx";
import { MockupCatalogo } from "../../components/Mockups/MockupCatalogo/MockupCatalogo.tsx";
import Titulo from "../../components/Modal/ModaisConfigsPrimeiroAcesso/Titulo/Titulo.tsx";
import SkeletonConfiguracoes from "../../components/Skeletons/SkeletonConfiguracoes/SkeletonConfiguracoes.tsx";
import { ToggleConfigurar } from "../../components/Toggle/ToggleConfigurar/ToggleConfigurar.tsx";
import { ConfiguracoesContext } from "../../contexts/Configuracoes/ConfiguracoesContext.tsx";
import { EmpresaContext } from "../../contexts/Empresa/EmpresaContext.tsx";
import { useTheme } from "../../contexts/Theme/ThemeContext.tsx";
import { useComponentMapping } from "./ComponentMapping.tsx";

interface ConfiguracoesProps {}

const Configuracoes: React.FC<ConfiguracoesProps> = ({}) => {
  const { configuracoes, setConfiguracoes, salvarConfiguracoes, loading } =
    useContext(ConfiguracoesContext);
  const { theme } = useTheme();
  const { anchor } = useParams();
  const { empresa } = useContext(EmpresaContext);

  const queryParams = new URLSearchParams(window.location.search);
  const ParamValueMelhorEnvio = queryParams.get("melhorEnvio");
  const ParamValueMercadoPago = queryParams.get("mercadoPago");

  const [formValid, setFormValid] = useState<boolean>(false);
  const formRef = useRef<any>(null);
  const [activeNode, setActiveNode] = useState<string>("");
  const [visualizarCatalogo, setVisualizarCatalogo] = useState<boolean>(false);
  const [activeChildren, setActiveChildren] =
    useState<string>("informacoesdamarca");
  const duration = 350;

  const componentMapping = useComponentMapping();
  const renderComponent = (id: string) =>
    componentMapping[id] && componentMapping[id].component();

  const componentsRefs = useRef<{ [key: string]: HTMLDivElement | null }>({});
  const scrollContainer = useRef<HTMLDivElement | null>(null);

  const handleTreeChange = (newNode: Node) => {
    setActiveChildren(newNode.id);
    // scroll to the active component relative to the scroll container without using scrollIntoView because for some reason it is a little glitchy
    const targetPosition =
      componentsRefs.current[newNode.id]?.offsetTop! -
      scrollContainer.current?.offsetTop!;
    scrollContainer.current?.scrollTo({
      top: targetPosition,
      behavior: "smooth",
    });

    //usar debounce pra checar se o scroll finalizou e ai scrollar a window pro topo
    const handleScroll = debounce(() => {
      const currentPosition = scrollContainer.current?.scrollTop!;
      //essa checagem é pra evitar que os ultimos itens da tree causem o scroll da window pro top e eles fiquem escondidos
      if (
        isBetween(currentPosition, targetPosition - 20, targetPosition + 20)
      ) {
        window.scrollTo(0, 0);
        scrollContainer.current?.removeEventListener("scroll", handleScroll);
      }
    }, 100);

    scrollContainer.current?.addEventListener("scroll", handleScroll);
    handleScroll();
  };

  const isBetween = (value: number, min: number, max: number) => {
    return value >= min && value <= max;
  };

  const handleSubmitConfigs = async () => {
    if (await salvarConfiguracoes()) {
      Swal.fire({
        icon: "success",
        title: "Configurações salvas com sucesso!",
        showConfirmButton: false,
        timer: 1000,
      });
    }
  };

  useEffect(() => {
    if (ParamValueMelhorEnvio === "autorizado") {
      setActiveChildren("melhorenvio");
      Swal.fire({
        icon: "success",
        title: "Melhor Envio autorizado com sucesso!",
        showConfirmButton: false,
        timer: 1000,
      });
    }
  }, [ParamValueMelhorEnvio]);


  useEffect(() => {
    if (ParamValueMercadoPago === "autorizado") {
      setActiveChildren("mercadopago");
      Swal.fire({
        icon: "success",
        title: "Mercado Pago autorizado com sucesso!",
        showConfirmButton: false,
        timer: 1000,
      });
    }
  }, [ParamValueMercadoPago]);

  const handleInvalidInput = (input: HTMLInputElement | HTMLSelectElement) => {
    let parent: HTMLElement | null = input;
    while (parent !== null) {
      if (parent.classList.contains("children-wrapper") && parent.id) {
        setActiveDropdown(parent.id);
        //scroll to the active component
      }
      if (parent.classList.contains("node-mobile")) {
        setActiveNode(parent.id);
      }
      parent = parent.parentElement || null;
    }
  };
  

  const [activeDropdown, setActiveDropdown] = useState<string | null>(null);

  useEffect(() => {
    // funcao para checar se o mouse está em cima de algum dos componentes da tree e setar o activeChildren
    const handleMouseMove = debounce((e) => {
      const mouseX = e.clientX;
      const mouseY = e.clientY;

      for (let key in componentsRefs.current) {
        const ref = componentsRefs.current[key];
        if (ref) {
          const rect = ref.getBoundingClientRect();

          if (
            mouseX >= rect.left &&
            mouseX <= rect.right &&
            mouseY >= rect.top &&
            mouseY <= rect.bottom &&
            !document.body.classList.contains("modal-shown") // evitar trocar quando algum modal estiver ativo
          ) {
            setActiveChildren(key);
            return;
          }
        }
      }
    }, 100);

    document.addEventListener("mousemove", handleMouseMove);

    return () => {
      document.removeEventListener("mousemove", handleMouseMove);
      handleMouseMove.cancel();
    };
  }, [componentsRefs, setActiveChildren]);

  useEffect(() => {
    if (!loading && anchor) {
      setTimeout(() => {
        setActiveChildren(anchor);
        scrollContainer.current?.scrollTo({
          top:
            componentsRefs.current[anchor]?.offsetTop! -
            scrollContainer.current?.offsetTop,
          behavior: "smooth",
        });
      }, duration);
    }
  }, [loading, anchor]);

  useEffect(() => {
    if (activeDropdown && !componentMapping[activeDropdown].value) {
      setActiveDropdown(null);
    }
  }, [componentMapping]);



  return (
    <ContainerConfiguracoes theme={theme}>
      <div className="grid-conteudo">
        {loading ? (
          <SkeletonConfiguracoes />
        ) : (
          <>
            <Tree
              activeNode={activeChildren}
              onChange={handleTreeChange}
              nodes={nodes as Node[]}
              className="hideMobile"
            />
            <div className="container-conteudo-configs">
              <Form
                id="form-configs"
                ref={formRef}
                onSubmit={() => handleSubmitConfigs()}
                setFormValid={setFormValid}
                onInvalid={handleInvalidInput}
                scrollTimeout={duration + 50}
              >
                <div className="configuracoes-container">
                  <div
                    className="container-scroll-configs scrollBonito"
                    ref={scrollContainer}
                  >
                    {nodes.map((node) => (
                      <>
                        <div className="node-mobile" id={node.id}>
                          <div
                            className={`node-mobile-title hideDesktop ${
                              activeNode === node.id
                                ? "node-mobile-title-active"
                                : ""
                            }`}
                            onClick={() =>
                              setActiveNode(
                                activeNode === node.id ? "" : node.id
                              )
                            }
                          >
                            <div className="node-mobile-title-text">
                              {node.name}
                            </div>
                            <Arrow
                              className={`angle ${
                                activeNode === node.id ? "up" : ""
                              }`}
                            />
                          </div>
                          <Dropdown
                            active={
                              activeNode === node.id || window.innerWidth > 1024
                            }
                            dropDownClassName="nodes-dropdown-mobile"
                          >
                            {node.children?.map((child) => {
                              return (
                                <div
                                  className="children-wrapper"
                                  key={child.id}
                                  id={child.id}
                                  ref={(ref) =>
                                    (componentsRefs.current[child.id] = ref)
                                  }
                                >
                                  {componentMapping[child.id] &&
                                  (componentMapping[child.id].title ||
                                    componentMapping[child.id].subtitle ||
                                    componentMapping[child.id].value !==
                                      undefined ||
                                    componentMapping[child.id].onChange) ? (
                                    <>
                                      <div className="flexTituloConfigs">
                                        {componentMapping[child.id].title &&
                                          componentMapping[child.id]
                                            .subtitle && (
                                            <Titulo
                                              titulo={
                                                componentMapping[child.id]
                                                  .title || ""
                                              }
                                              subtitulo={
                                                componentMapping[child.id]
                                                  .subtitle || ""
                                              }
                                              justifyContent="flex-start"
                                              margin="0"
                                              padding="0"
                                            />
                                          )}
                                        {componentMapping[child.id].value !==
                                          undefined &&
                                          componentMapping[child.id]
                                            .onChange && (
                                            <ToggleConfigurar
                                              value={
                                                componentMapping[child.id]
                                                  .value || false
                                              }
                                              onConfigurar={(e) => {
                                                e.preventDefault();
                                                e.stopPropagation();
                                                const newValue =
                                                  activeDropdown === child.id
                                                    ? null
                                                    : child.id;
                                                if (!formValid) {
                                                  formRef.current?.submitForm();
                                                  return;
                                                }
                                                setActiveDropdown(newValue);
                                                //scroll if active
                                                if (newValue !== null) {
                                                  setTimeout(() => {
                                                    scrollContainer.current?.scrollTo(
                                                      {
                                                        top:
                                                          componentsRefs
                                                            .current[child.id]
                                                            ?.offsetTop! -
                                                          scrollContainer
                                                            .current?.offsetTop,
                                                        behavior: "smooth",
                                                      }
                                                    );
                                                  }, duration);
                                                }
                                              }}
                                              onChange={(newValue) => {
                                                if (
                                                  !formValid &&
                                                  activeDropdown !== child.id
                                                ) {
                                                  formRef.current?.submitForm();
                                                  return;
                                                }
                                                const result =
                                                  componentMapping[child.id]
                                                    .onChange!(newValue);
                                                if (
                                                  newValue &&
                                                  result !== false
                                                ) {
                                                  setActiveDropdown(child.id);
                                                  //scroll to the active component
                                                  setTimeout(() => {
                                                    scrollContainer.current?.scrollTo(
                                                      {
                                                        top:
                                                          componentsRefs
                                                            .current[child.id]
                                                            ?.offsetTop! -
                                                          scrollContainer
                                                            .current?.offsetTop,
                                                        behavior: "smooth",
                                                      }
                                                    );
                                                  }, duration);
                                                } else {
                                                  setActiveDropdown(null);
                                                }
                                              }}
                                            />
                                          )}
                                      </div>
                                      <Dropdown
                                        active={
                                          activeDropdown === child.id ||
                                          componentMapping[child.id].value ===
                                            undefined
                                        }
                                        duration={duration}
                                      >
                                        {renderComponent(child.id)}
                                      </Dropdown>
                                    </>
                                  ) : (
                                    <>{renderComponent(child.id)}</>
                                  )}
                                </div>
                              );
                            })}
                          </Dropdown>
                        </div>
                      </>
                    ))}
                  </div>
                </div>
                <FooterPaginas>
                  <div className="footer-configs">
                    <Button
                      className={`${
                        !formValid && ""
                      } button-visualizar hideDesktop`}
                      title={visualizarCatalogo ? "Esconder" : "Visualizar"}
                      onClick={() => {
                        setVisualizarCatalogo(!visualizarCatalogo);
                      }}
                      padding="none"
                    />
                    <Button
                      className={`${!formValid && "disabled"} button-salvar`}
                      type="submit"
                      title="Salvar"
                      padding="none"
                    />
                  </div>
                  <div className={`visualizacao-ativa`}>
                    <Dropdown
                      active={visualizarCatalogo}
                      dropDownClassName="dropdown-visualizacao"
                      className="div-visualizacao"
                    >
                      <MockupCatalogo
                        menu={true}
                        banner={false}
                        configuracoes={configuracoes}
                      />
                    </Dropdown>
                  </div>
                </FooterPaginas>
              </Form>
            </div>
          </>
        )}
      </div>
    </ContainerConfiguracoes>
  );
};

export default Configuracoes;
