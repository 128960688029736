import { useContext } from "react";
import ChaveIntegracao from "../../components/Configuracoes/ChaveIntegracao/ChaveIntegracao.tsx";
import { Compartilhamento } from "../../components/Configuracoes/Compartilhamento/Compartilhamento.tsx";
import { ConfigCorreios } from "../../components/Configuracoes/ConfigCorreios/ConfigCorreios.tsx";
import Endereco from "../../components/Configuracoes/Endereco/Endereco.tsx";
import EntregaLocal from "../../components/Configuracoes/EntregaLocal/EntregaLocal.tsx";
import FreteEspecialMain from "../../components/Configuracoes/FreteEspecialMain/FreteEspecialMain.tsx";
import FreteGratisMain from "../../components/Configuracoes/FreteGratisMain/FreteGratisMain.tsx";
import InformacoesDaMarca from "../../components/Configuracoes/InformacoesDaMarca/InformacoesDaMarca.tsx";
import IntegracaoBling from "../../components/Configuracoes/IntegracaoBling/IntegracaoBling.tsx";
import IntegracaoBlingV2 from "../../components/Configuracoes/IntegracaoBlingV2/IntegracaoBlingV2.tsx";
import IntegracaoTiny from "../../components/Configuracoes/IntegracaoTiny/IntegracaoTiny.tsx";
import MelhorEnvio from "../../components/Configuracoes/MelhorEnvio/MelhorEnvio.tsx";
import PagamentoCombinar from "../../components/Configuracoes/PagamentoCombinar/PagamentoCombinar.tsx";
import PagamentoMain from "../../components/Configuracoes/PagamentoOnline/PagamentoOnline.tsx";
import { Pedido } from "../../components/Configuracoes/Pedido/Pedido.tsx";
import { PoliticaDePrivacidade } from "../../components/Configuracoes/PoliticaDePrivacidade/PoliticaDePrivacidade.tsx";
import { PoliticaDeTrocaeDevolucao } from "../../components/Configuracoes/PoliticaDeTrocaeDevolucao/PoliticaDeTrocaeDevolucao.tsx";
import RetiradaNoLocal from "../../components/Configuracoes/RetiradaNoLocal/RetiradaNoLocal.tsx";
import VisualMain from "../../components/Configuracoes/VisualMain/VisualMain.tsx";
import { ConfiguracoesContext } from "../../contexts/Configuracoes/ConfiguracoesContext.tsx";
import { IntegracaoContext } from "../../contexts/Integracao/IntegracaoContext.tsx";
import { useTheme } from "../../contexts/Theme/ThemeContext.tsx";
import { EmpresaApi } from "../../hooks/empresaApi.ts";
import CuponsMain from "../../components/Configuracoes/cuponsMain/CuponsMain.tsx";
import { FacebookPixel } from "../../components/Configuracoes/FacebookPixel/FacebookPixel.tsx";
import { VerificacoesDeDominio } from "../../components/Configuracoes/VerificacoesDeDominio/VerificacoesDeDominio.tsx";
import { Google } from "../../components/Configuracoes/Google/Google.tsx";

interface ComponentItem {
  component: () => JSX.Element;
  title?: string;
  subtitle?: string;
  value?: boolean;
  dropdown?: boolean;
  onChange?: (newValue: boolean) => void | boolean; //on change pro toogle que ativa o dropdown
}

const isBlingV2 = (configuracoes) => {
  return (
    configuracoes.dados_bling_v2 !== undefined ||
    (!configuracoes.dados_bling === undefined &&
      configuracoes.dados_bling_v2 === undefined)
  );
};

export const useComponentMapping = () => {
  const empresaApi = EmpresaApi();
  const {
    chaveIntegracao,
    setChaveIntegracao,
    activeChaveIntegracao,
    setActiveChaveIntegracao,
    onChangeIntegracao,
    onChangeIntegracaoBling,
    onChangeIntegracaoTiny,
    onChangeIntegracaoBlingV2,
  } = useContext(IntegracaoContext);
  const { theme } = useTheme();
  const { configuracoes, setConfiguracoes } = useContext(ConfiguracoesContext);
  const componentMapping: { [key: string]: ComponentItem } = {
    informacoesdamarca: {
      component: () => <InformacoesDaMarca configIn="configuracoes" />,
    },
    endereco: {
      component: () => <Endereco configIn="configuracoes" />,
    },
    cupons: {
      component: () => <CuponsMain />,
    },
    correios: {
      component: () => <ConfigCorreios configIn="configuracoes" />,
      title: "Correios",
      subtitle: "Entrega e Retirada",
      value: configuracoes.frete.correiosAtivo,
      onChange: (newValue) => {
        setConfiguracoes({
          ...configuracoes,
          frete: {
            ...configuracoes.frete,
            correiosAtivo: newValue,
          },
        });
      },
    },
    melhorenvio: {
      component: () => <MelhorEnvio />,
      title: "Melhor Envio",
      subtitle: "Entrega e Retirada",
      value: configuracoes.frete.melhorEnvio.ativo,
      onChange: (newValue) => {
        setConfiguracoes({
          ...configuracoes,
          frete: {
            ...configuracoes.frete,
            melhorEnvio: {
              ...configuracoes.frete.melhorEnvio,
              ativo: newValue,
            },
          },
        });
      },
    },
    entregalocal: {
      component: () => <EntregaLocal configIn="configuracoes" />,
      title: "Entrega local",
      subtitle: "Entrega e Retirada",
      value: configuracoes.frete.entregaLocal.ativo,
      onChange: (newValue) => {
        setConfiguracoes({
          ...configuracoes,
          frete: {
            ...configuracoes.frete,
            entregaLocal: {
              ...configuracoes.frete.entregaLocal,
              ativo: newValue,
            },
          },
        });
      },
    },
    entregaespecial: {
      component: () => <FreteEspecialMain configIn="configuracoes" />,
      title: "Entrega especial",
      subtitle: "Entrega e Retirada",
      value: configuracoes.frete.freteEspecialAtivo,
      onChange: (newValue) => {
        setConfiguracoes({
          ...configuracoes,
          frete: {
            ...configuracoes.frete,
            freteEspecialAtivo: newValue,
          },
        });
      },
    },
    fretegratis: {
      component: () => <FreteGratisMain />,
      title: "Frete grátis",
      subtitle: "Entrega e Retirada",
      value: configuracoes.frete.freteGratisAtivo,
      onChange: (newValue) => {
        setConfiguracoes({
          ...configuracoes,
          frete: {
            ...configuracoes.frete,
            freteGratisAtivo: newValue,
          },
        });
      },
    },
    retiradanolocal: {
      component: () => <RetiradaNoLocal configIn="configuracoes" />,
      title: "Retirada no local",
      subtitle: "Entrega e Retirada",
      value: configuracoes.frete.retirada_no_local.ativo,
      onChange: (newValue) => {
        setConfiguracoes({
          ...configuracoes,
          frete: {
            ...configuracoes.frete,
            retirada_no_local: {
              ...configuracoes.frete.retirada_no_local,
              ativo: newValue,
            },
          },
        });
      },
    },
    pagamentoonline: {
      component: () => <PagamentoMain />,
      title: "Pagamento online",
      subtitle: "Pagamento",
      value: configuracoes.pagamentoOnlineAtivo,
      onChange: (newValue) => {
        setConfiguracoes({
          ...configuracoes,
          pagamentoOnlineAtivo: newValue,
        });
      },
    },
    pagamentoacombinar: {
      component: () => <PagamentoCombinar configIn="configuracoes" />,
      title: "Pagamento a combinar",
      subtitle: "Pagamento",
      value: configuracoes.pagamentoCombinarAtivo,
      onChange: (newValue) => {
        setConfiguracoes({
          ...configuracoes,
          pagamentoCombinarAtivo: newValue,
        });
      },
    },
    pedido: {
      component: () => <Pedido configIn="configuracoes" />,
      title: "Pedido",
      subtitle: "Configure seu catálogo",
    },
    politicadeprivacidade: {
      component: () => <PoliticaDePrivacidade configIn="configuracoes" />,
      title: "Política de privacidade",
      subtitle: "Configure seu catálogo",
    },
    politicadetrocaedevolucao: {
      component: () => <PoliticaDeTrocaeDevolucao configIn="configuracoes" />,
      title: "Política de troca e devolução",
      subtitle: "Configure seu catálogo",
    },
    compartilhamento: {
      component: () => <Compartilhamento configIn="configuracoes" />,
      title: "Compartilhamento",
      subtitle: "Configure seu catálogo",
    },
    visual: {
      component: () => <VisualMain />,
      title: "Visual",
      subtitle: "Configure seu catálogo",
    },
    chaveintegracao: {
      component: () => (
        <ChaveIntegracao
          chave={chaveIntegracao}
          setChaveDeIntegracao={setChaveIntegracao}
        />
      ),
      title: "Chave de API",
      subtitle: "Integrações",
      value: activeChaveIntegracao,
      onChange: onChangeIntegracao,
    },
    integracaodobling: {
      component: () =>
        isBlingV2(configuracoes) ? <IntegracaoBlingV2 /> : <IntegracaoBling />,
      title: "Bling",
      subtitle: "Integrações",
      value: isBlingV2(configuracoes)
        ? configuracoes.dados_bling_v2!.ativo
        : configuracoes.dados_bling.ativo,
      onChange: isBlingV2(configuracoes)
        ? onChangeIntegracaoBlingV2
        : onChangeIntegracaoBling,
    },
    integracaodatiny: {
      component: () => <IntegracaoTiny />,
      title: "Tiny",
      subtitle: "Integrações",
      value: configuracoes.dados_tiny_v2.ativo,
      onChange: onChangeIntegracaoTiny,
    },
    verificacoesdedominio: {
      component: () => <VerificacoesDeDominio />,
      title: "Verificações de domínio",
      value: configuracoes.verificacoes_de_dominio.ativo,
      subtitle: "Integrações",
      onChange: (newValue) => {
        setConfiguracoes({
          ...configuracoes,
          verificacoes_de_dominio: {
            ...configuracoes.verificacoes_de_dominio,
            ativo: newValue,
          },
        });
      },
    },
    facebookpixel: {
      component: () => <FacebookPixel />,
      title: "Meta",
      subtitle: "Integrações",
      value: configuracoes.dados_facebook_pixel.ativo,
      onChange: (newValue) => {
        setConfiguracoes({
          ...configuracoes,
          dados_facebook_pixel: {
            ...configuracoes.dados_facebook_pixel,
            ativo: newValue,
          },
        });
      },
    },
    google: {
      component: () => <Google />,
      title: "Google",
      subtitle: "Integrações",
      value: configuracoes.dados_google.ativo,
      onChange: (newValue) => {
        setConfiguracoes({
          ...configuracoes,
          dados_google: {
            ...configuracoes.dados_google,
            ativo: newValue,
          },
        });
      },
    }
  };
  return componentMapping;
};
