import { useContext, useEffect, useRef, useState } from "react";
import { ConfiguracoesContext } from "../../../contexts/Configuracoes/ConfiguracoesContext";
import { EmpresaContext } from "../../../contexts/Empresa/EmpresaContext";
import { useTheme } from "../../../contexts/Theme/ThemeContext";
import { mercadoPagoApi } from "../../../hooks/mercadoPagoApi";
import { pagarmeApi } from "../../../hooks/pagarmeApi";
import { pagSeguroApi } from "../../../hooks/pagSeguroApi";
import { copy, deepCopy } from "../../../services/Functions";
import Dropdown from "../../Dropdowns/Dropdown";
import { Input } from "../../Input";
import Titulo from "../../Modal/ModaisConfigsPrimeiroAcesso/Titulo/Titulo";
import ModalRelativo from "../../ModalRelativo/ModalRelativo";
import { ToggleConfigurar } from "../../Toggle/ToggleConfigurar/ToggleConfigurar";
import Intermediadores from "../Intermediadores/Intermediadores";
import PagamentoCombinar from "../PagamentoCombinar/PagamentoCombinar";
import { ContainerPagamentoContainer } from "./styles";

type PossibleIntermediadores =
  | "pagarme"
  | "pagseguro"
  | "mercadopago"
  | "pagarmehub";
interface Intermediador {
  verificao?: () => Promise<boolean>;
  titulo: string;
  subtitulo: string;
  botaoConfirmar: string;
  onConfirm?: () => void;
  // para casos de integração usando uma chave de api, utilize esse campo
  campoChave?: string;
}
interface PagamentoProps {
  onChange?: (newValue: PossibleIntermediadores) => void;
  configIn: "configuracoes" | "empresa";
}

const Pagamento: React.FC<PagamentoProps> = ({ configIn, onChange }) => {
  const { theme } = useTheme();
  const [loading, setLoading] = useState<boolean>(false);

  const { configuracoes, setConfiguracoes } = useContext(ConfiguracoesContext);

  const [configuracoesModaisAuxiliar, setConfiguracoesModaisAuxiliar] =
    useState(deepCopy(configuracoes));

  const [autenticado, setAutenticado] = useState<boolean>(false);

  const PagSeguroApi = pagSeguroApi();
  const MercadoPagoApi = mercadoPagoApi();
  const PagarmeApi = pagarmeApi();

  const { empresa, setEmpresa } = useContext(EmpresaContext);
  const nomeUsuario = empresa?.usuario;

  const [pagamentoDropdown, setPagamentoDropdown] = useState<boolean>(false);
  const linkAutorizacao = useRef<string>("");

  const [modalAutenticacao, setModalAutenticacao] = useState(false);

  const ignoreValidations =
    configIn === "configuracoes" && !configuracoes.pagamentoOnlineAtivo
      ? true
      : false;

  const verificarAtivacaoPagarme = async () => {
    if (
      configuracoes.intermediadores.pagarme &&
      configuracoes.intermediadores.pagarme.chaveSecreta &&
      configuracoes.intermediadores.pagarme.chaveSecreta !== ""
    ) {
      return true;
    }
    return false;
  };

  const verificarAtivacaoPagarmeHub = async () => {
    const res = await PagarmeApi.verificarAutenticacao();
    return res === true;
  };

  const verificarAtivacaoPagseguro = async () => {
    const { linkAutorizacao: newLinkAutorizacao, ativado } =
      await PagSeguroApi.getLinkAutorizacao();

    linkAutorizacao.current = newLinkAutorizacao;
    return ativado ? true : false;
  };

  const verificarAtivacaoMercadoPago = async () => {
    const res = await MercadoPagoApi.verificarAutenticacao();
    //como os tokens estao nas configuracoes, atualiza-los pra hora de salvar
    //os outros intermediadores estao no configuracoes_dinamicas, por isso nao precisa
    if (res.ativo) {
      setConfiguracoes({
        ...configuracoes,
        dados_mercadoPago: {
          ...res,
        },
      });
    }
    return res.ativo ? true : false;
  };

  const areFieldsValid = (intermediador: PossibleIntermediadores) => {
    return configuracoes.intermediadores[intermediador] &&
    (!autenticado ||
      (!configuracoes.intermediadores[intermediador].cartao.ativo &&
        !configuracoes.intermediadores[intermediador].pix.ativo))
      ? false
      : true;
  }

  const intermediadores: Record<PossibleIntermediadores, Intermediador> = {
    pagseguro: {
      verificao: verificarAtivacaoPagseguro,
      titulo: 'PagBank',
      subtitulo: 'Para utilizar a integração de <br/> pagamentos com o PagBank é <br/> preciso fazer login em sua conta.',
      botaoConfirmar: 'Fazer login no PagBank',
      onConfirm: () => {
        //nesse caso o link vem do backend junto da funcao de verificacao, e é setado no ref
        window.open(linkAutorizacao.current, "_blank");
      },
    },
    pagarme: {
      verificao: verificarAtivacaoPagarme,
      campoChave: "chaveSecreta",
      titulo: "Pagar.me",
      subtitulo:
        "Para utilizar a integração de pagamentos com <br/> o Pagar.me insira a chave secreta abaixo.",
      botaoConfirmar: "Salvar e continuar",
      onConfirm: () => {
        //so chamar pra atualizar o estado
        handleVisibilityChangeRef.current();
      },
    },
    pagarmehub: {
      verificao: verificarAtivacaoPagarmeHub,
      titulo: "Pagar.me Hub",
      subtitulo:
        "Para utilizar a integração de pagamentos com <br/> o Pagar.me, é preciso autorizar no hub.",
      botaoConfirmar: "Autorizar no Pagar.me Hub",
      onConfirm: () => {
        const isDev = window.location.href.includes("dev");
        const isLocalhost = window.location.href.includes("localhost");

        const config = {
          environment: isDev || isLocalhost ? "dev" : "",
          publicAppKey: "609c232d-82a5-4a52-b14c-6e3da4ed938b",
          redirectUrl: isLocalhost
            ? "http://localhost/conecta-painel-api/api/pagarme_hub/autenticacao"
            : isDev
            ? "https://painel-api-dev.conectavenda.com.br/pagarme_hub/autenticacao"
            : "https://painel-api.conectavenda.com.br/pagarme_hub/autenticacao",
        };

        //nesse caso o link pode ser montado direto no frontend
        const url = `https://hub.pagar.me/apps/${
          config.environment === "dev" ? "dev/" : ""
        }${config.publicAppKey}/authorize?redirect=${
          config.redirectUrl
        }?state=${nomeUsuario}&env=${config.environment}`;
        window.open(url, "_blank");
      },
    },
    mercadopago: {
      verificao: verificarAtivacaoMercadoPago,
      titulo: "Mercado Pago",
      subtitulo:
        "Para utilizar a integração de <br/> pagamentos com o Mercado pago é <br/> preciso fazer login em sua conta.",
      botaoConfirmar: "Fazer login no Mercado pago",
      onConfirm: () => {
        //nesse caso o link pode ser montado direto no frontend
        const url =
          window.location.href.includes("localhost") ||
          window.location.href.includes("dev")
            ? "https://painel-api-dev.conectavenda.com.br"
            : "https://painel-api.conectavenda.com.br";
        window.open(
          `https://auth.mercadopago.com/authorization?client_id=5130116869055423&response_type=code&platform_id=mp&state=${nomeUsuario}&redirect_uri=${url}/mercado_pago/autenticacao
        `,
          "_blank"
        );
      },
    },
  };
  const intermediadoresKeys = Object.keys(
    intermediadores
  ) as PossibleIntermediadores[];

  const intermediador = Object.keys(configuracoes.intermediadores).filter(
    (key) => intermediadoresKeys.includes(key as any)
  )[0] as PossibleIntermediadores;

  const handleVisibilityChange = async (
    intermediador: PossibleIntermediadores
  ) => {
    if (document.visibilityState === "visible") {
      if (
        intermediadoresKeys.includes(intermediador) &&
        intermediadores[intermediador].verificao
      ) {
        setLoading(true);
        const res = await intermediadores[intermediador].verificao();
        if (res) {
          setAutenticado(true);
          if (modalAutenticacao) {
            setModalAutenticacao(false);
            onChange && onChange(intermediador);
            setPagamentoDropdown(true);
          }
          document.removeEventListener(
            "visibilitychange",
            handleVisibilityChangeRef.current
          );
        } else {
          setAutenticado(false);
          setModalAutenticacao(true);
        }
        setLoading(false);
      }
    }
  };

  const handleVisibilityChangeRef = useRef<() => Promise<void>>(async () => {});

  const verificarAuntenticacao = async () => {
    if (intermediadores[intermediador].verificao) {
      handleVisibilityChangeRef.current = () =>
        handleVisibilityChange(intermediador);
      await handleVisibilityChangeRef.current();
    }
  };

  useEffect(() => {
    if (
      intermediadoresKeys.includes(intermediador) &&
      intermediadores[intermediador].verificao
    ) {
      verificarAuntenticacao();
    }

    return () => {
      removerListener();
    };
  }, []);

  //eventos do botao do modal de autenticacao
  const onConfirm = () => {
    removerListener();
    //remover se ja houver outro
    handleVisibilityChangeRef.current = () =>
      handleVisibilityChange(intermediador);

    if (!intermediadores[intermediador].campoChave) {
      //adicionar um listener para verificar a autenticacao
      document.addEventListener(
        "visibilitychange",
        handleVisibilityChangeRef.current
      );
    }

    if (intermediadores[intermediador].onConfirm) {
      intermediadores[intermediador].onConfirm();
    }
  };

  const removerListener = () => {
    document.removeEventListener(
      "visibilitychange",
      handleVisibilityChangeRef.current
    );
  };

  const onCancel = () => {
    setConfiguracoes(configuracoesModaisAuxiliar);
    removerListener();
  };

  const onConfigurarIntermediador = async () => {
    console.log(autenticado);
    if (autenticado) {
      onChange && onChange(intermediador);
      setPagamentoDropdown(!pagamentoDropdown);
    } else {
      await verificarAuntenticacao();
    }
  };

  const onChangeIntermediadorToggle = (
    intermediador: PossibleIntermediadores,
    newValue: boolean
  ) => {
    if (newValue) {
      let copia = copy(configuracoes);
      copia.intermediadores[intermediador] = {
        cartao: {
          ativo: true,
          quantidade_maxima_parcelas: 0,
          valor_minimo_parcela: 0,
        },
        pix: {
          ativo: true,
          percentual_desconto: 0,
        },
      };
      if (configIn === "empresa") {
        copia.pagamentoOnlineAtivo = true;
      }
      intermediadoresKeys.forEach((key) => {
        if (key !== intermediador) {
          delete copia.intermediadores[key];
        }
      });
      setConfiguracoes(copia);
    } else {
      let copia = copy(configuracoes);
      delete copia.intermediadores[intermediador];
      if (intermediador === "mercadopago") {
        delete copia.dados_mercadoPago;
      }
      if (configIn === "empresa") {
        copia.pagamentoOnlineAtivo = false;
      }
      setConfiguracoes(copia);
    }
    setAutenticado(false);
  };

  return (
    <>
      <ContainerPagamentoContainer theme={theme} configIn={configIn}>
        <div className="containerPagamento">
          {configIn === "empresa" && (
            <Titulo
              titulo={"Pagamento"}
              subtitulo={
                "Selecione como você pode receber e configure as </br> formas de pagamento aceitas em seu catálogo."
              }
              flexDirection="column-reverse"
            ></Titulo>
          )}
          <div className="online">
            <div className="titulo">Pagamento online</div>
            {intermediadoresKeys.map(
              (intermediadorKey: PossibleIntermediadores) => {
                if(intermediadorKey === "pagarme" && !configuracoes.intermediadores.pagarme){
                  return null;
                }

                return (
                  <>
                    <span className={`wrapperToggleConfigurarPagamento`}>
                      <ToggleConfigurar
                        disabled={loading}
                        ignoreValidations={ignoreValidations}
                        nome={intermediadores[intermediadorKey].titulo}
                        value={configuracoes.intermediadores.hasOwnProperty(
                          intermediadorKey
                        )}
                        isValid={areFieldsValid(intermediadorKey)}
                        invalidText={`Configure seu ${intermediadores[intermediadorKey].titulo}`}
                        onChange={(newValue) =>
                          onChangeIntermediadorToggle(
                            intermediadorKey,
                            newValue
                          )
                        }
                        onConfigurar={onConfigurarIntermediador}
                      />
                    </span>
                    {configIn !== "empresa" &&
                      intermediadorKey === intermediador && (
                        <Dropdown active={pagamentoDropdown}>
                          {configuracoes.intermediadores[intermediadorKey] && (
                            <Intermediadores
                              configIn="configuracoes"
                              intermediador={intermediadorKey}
                            ></Intermediadores>
                          )}
                        </Dropdown>
                      )}
                  </>
                );
              }
            )}
            {modalAutenticacao &&
              intermediadoresKeys.includes(intermediador) && (
                <ModalRelativo
                  normalModal={configIn === "configuracoes" ? true : false}
                  isOpen={modalAutenticacao}
                  setIsOpen={setModalAutenticacao}
                  titulo={intermediadores[intermediador].titulo}
                  subtitulo={intermediadores[intermediador].subtitulo}
                  onUseEffect={() => {
                    setConfiguracoesModaisAuxiliar(deepCopy(configuracoes));
                  }}
                  width="auto"
                  center={true}
                  botaoConfirmar={intermediadores[intermediador].botaoConfirmar}
                  botaoCancelar="Voltar"
                  cancelar={true}
                  padding="5rem 10rem"
                  onConfirm={onConfirm}
                  onCancel={onCancel}
                >
                  {intermediadores[intermediador].campoChave && (
                    <div className="chaveSecretaContainer">
                      <Input
                        ignoreValidations={ignoreValidations}
                        type={"text"}
                        value={
                          configuracoes.intermediadores[intermediador]?.[
                            intermediadores[intermediador].campoChave
                          ]
                            ? configuracoes.intermediadores[intermediador]?.[
                                intermediadores[intermediador].campoChave
                              ]
                            : ""
                        }
                        placeholder={"Chave"}
                        label="Chave Secreta"
                        alignLabel="center"
                        onChange={(newValue) => {
                          setConfiguracoes({
                            ...configuracoes,
                            intermediadores: {
                              ...configuracoes.intermediadores,
                              [intermediador]: {
                                ...configuracoes.intermediadores[intermediador],
                                [intermediadores[intermediador].campoChave!]:
                                  newValue,
                              },
                            },
                          });
                        }}
                        sizeLabel="medium"
                      />
                    </div>
                  )}
                </ModalRelativo>
              )}
          </div>
          {configIn === "empresa" && <PagamentoCombinar configIn="empresa" />}
        </div>
      </ContainerPagamentoContainer>
    </>
  );
};
export default Pagamento;
