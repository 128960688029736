import { deepMerge } from "../services/Functions";
import Configuracoes from "../types/Configuracoes";
import { Cupom } from "../types/Cupom";
import { FreteEspecial } from "../types/FreteEspecial";
import { FreteGratis } from "../types/FreteGratis";
import { api } from "./useApi";

export const configuracoesApi = () => ({
  select_configuracoes_edicao: async () => {
    try {
      let [configuracoes, politica_de_privacidade, politica_de_troca_e_devolucao] = await Promise.all([
        api.post("/configuracoes/select_configuracoes_edicao"),
        api.post("/configuracoes/buscar_politica_privacidade"),
        api.post("/configuracoes/buscar_politica_troca_devolucao"),
      ]);

      const formatarVersaoNovaConfiguracoes = (
        configuracoes: any,
        isFretesEspeciaisAtivos,
        isFretesGratisAtivos,
      ) => {
        const campos_pedido_geral = [
          { nome: "E-mail", campo: "email", ativo: false, obrigatorio: false },
          {
            nome: "Telefone (WhatsApp)",
            campo: "telefone",
            ativo: false,
            obrigatorio: false,
          },
          {
            nome: "Observação",
            campo: "observacao",
            ativo: false,
            obrigatorio: false,
          },
          { nome: "CEP", campo: "cep", ativo: false, obrigatorio: false },
          { nome: "Estado", campo: "estado", ativo: false, obrigatorio: false },
          { nome: "Cidade", campo: "cidade", ativo: false, obrigatorio: false },
          {
            nome: "Endereço",
            campo: "endereco",
            ativo: false,
            obrigatorio: false,
          },
          { nome: "Número", campo: "numero", ativo: false, obrigatorio: false },
          {
            nome: "Complemento",
            campo: "complemento",
            ativo: true,
            obrigatorio: true,
          },
          { nome: "Bairro", campo: "bairro", ativo: false, obrigatorio: false },
        ];
        const campos_pedido_pessoa_fisica = [
          {
            nome: "Nome completo",
            campo: "nome",
            ativo: true,
            obrigatorio: true,
          },
          { nome: "CPF", campo: "cpf", ativo: false, obrigatorio: false },
          {
            nome: "Data de nascimento",
            campo: "data_nascimento",
            ativo: false,
            obrigatorio: false,
          },
        ];
        const campos_pedido_pessoa_juridica = [
          {
            nome: "Razão Social",
            campo: "razao_social",
            ativo: true,
            obrigatorio: true,
          },
          { nome: "CNPJ", campo: "cnpj", ativo: false, obrigatorio: false },
          {
            nome: "Inscrição Estadual",
            campo: "inscricao_estadual",
            ativo: false,
            obrigatorio: false,
          },
        ];

        const camposAuxiliar = [
          campos_pedido_geral,
          campos_pedido_pessoa_fisica,
          campos_pedido_pessoa_juridica,
        ];

        [
          configuracoes.campos_pedido_geral,
          configuracoes.campos_pedido_pessoa_fisica,
          configuracoes.campos_pedido_pessoa_juridica,
        ].map((campos, indexCampos) => {
          for (let i = 0; i < camposAuxiliar[indexCampos].length; i++) {
            if (
              !campos[i] ||
              camposAuxiliar[indexCampos][i].campo !== campos[i].campo
            )
              campos.splice(i, 0, camposAuxiliar[indexCampos][i]); // insere o campos[i] no index certo caso ele nao exista

            if (campos[i].hasOwnProperty("opcao")) {
              campos[i].campo === "nome" || campos[i].campo === "razao_social"
                ? (campos[i].obrigatorio = true)
                : (campos[i].obrigatorio = false);
              campos[i].ativo = campos[i].opcao === 2 || campos[i].opcao === 1;
              if (campos[i].campo === "telefone") campos[i].nome = "Telefone (WhatsApp)";
              delete campos[i].opcao;
            }
            if (campos[i].hasOwnProperty("mostrar")) delete campos[i].mostrar;
          }
          campos.splice(camposAuxiliar[indexCampos].length, campos.length); // remove os campos que sobraram
        });

        if (
          !configuracoes.dados_empresa.endereco.hasOwnProperty(
            "ocultar_endereco_catalogo",
          )
        )
          configuracoes.dados_empresa.endereco.ocultar_endereco_catalogo =
            false;
        if (!configuracoes.frete.configuracoes.hasOwnProperty("contrato"))
          configuracoes.frete.configuracoes.contrato = '';
        if (!configuracoes.frete.dimensoes.hasOwnProperty("peso"))
          configuracoes.frete.dimensoes.peso = 0;
        if (!configuracoes.frete.hasOwnProperty("correiosAtivo"))
          configuracoes.frete.correiosAtivo = configuracoes.frete.opcoes.some(
            (opcao) => opcao.ativo,
          );
        if (!configuracoes.frete.hasOwnProperty("freteGratisAtivo"))
          configuracoes.frete.freteGratisAtivo = isFretesGratisAtivos;
        if (!configuracoes.frete.hasOwnProperty("freteEspecialAtivo"))
          configuracoes.frete.freteEspecialAtivo = isFretesEspeciaisAtivos;
        if (!configuracoes.frete.hasOwnProperty("entregaLocal"))
          configuracoes.frete.entregaLocal = {
            ativo: false,
            cepDoLocal: "",
            semTaxa: false,
            comTaxa: {
              ativo: false,
              valor: 0,
            },
          };
        if (!configuracoes.frete.hasOwnProperty("retirada_no_local"))
          configuracoes.frete.retirada_no_local = {
            ativo: false,
            intervalos: [
              {
                dias: [],
                horarios: [
                  {
                    abre: "",
                    fecha: "",
                  },
                ],
              },
            ],
          };
        if (!configuracoes.hasOwnProperty("pagamentoCombinarAtivo"))
          configuracoes.pagamentoCombinarAtivo = false;
        if (!configuracoes.hasOwnProperty("pagamentoOnlineAtivo"))
          configuracoes.pagamentoOnlineAtivo = false;
        if (configuracoes.hasOwnProperty("intermediadores")) {
          let intermediadorAtivo = '';
          let intermediadorDefault = {
            cartao: {
              ativo: false,
              quantidade_maxima_parcelas: '1',
              valor_minimo_parcela: '0',
            },
            pix: {
              ativo: false,
              percentual_desconto: '0',
            }
          }
          if (configuracoes.intermediadores.hasOwnProperty("pagseguro")) intermediadorAtivo = "pagseguro";
          else if (configuracoes.intermediadores.hasOwnProperty("pagarme")) intermediadorAtivo = "pagarme";
          if (intermediadorAtivo !== '') {
            deepMerge(configuracoes.intermediadores[intermediadorAtivo], intermediadorDefault);
          }
          if (intermediadorAtivo === 'pagarme') {
            if (!configuracoes.intermediadores.pagarme.hasOwnProperty("chaveSecreta")) configuracoes.intermediadores.pagarme.chaveSecreta = '';
          }
        }

        return configuracoes;
      };

      if (configuracoes.data.erro) {
        return configuracoes.data;
      }

      configuracoes.data.configuracoes = formatarVersaoNovaConfiguracoes(
        configuracoes.data.configuracoes,
        configuracoes.data.fretes_especiais.lenght > 0,
        configuracoes.data.fretes_gratis.lenght > 0,
      );

      configuracoes.data.politica_de_privacidade =
        politica_de_privacidade.data[0].politica_privacidade;

      configuracoes.data.politica_de_troca_e_devolucao =
        politica_de_troca_e_devolucao.data[0].politica_troca_devolucao;

      return configuracoes.data;
    } catch (error) {
      console.log(error);
    }
  },

  salvar_configuracoes: async (empresa: {
    configuracoes: Configuracoes;
    fretes_especiais: Array<FreteEspecial>;
    fretes_gratis: Array<FreteGratis>;
    cupons: Array<Cupom>;
    politica_de_privacidade: string;
    politica_de_troca_e_devolucao: string;
    erp_modificado: boolean | null;
  }) => {
    const politicaPrivacidadePromise = api.post(
      "configuracoes/salvar_politica_privacidade",
      {
        texto: empresa.politica_de_privacidade,
      },
    );
    const politicaTrocaeDevolucaoPromise = api.post(
      "configuracoes/salvar_politica_troca_devolucao",
      {
        texto: empresa.politica_de_troca_e_devolucao,
      },
    );
    const layoutPromise = api.post("configuracoes/salvar_layout2", {
      informacoes: {
        configuracoes: empresa.configuracoes,
        fretes_especiais: empresa.fretes_especiais,
        cupons: empresa.cupons,
        fretes_gratis: empresa.fretes_gratis,
        erp_modificado: empresa.erp_modificado,
      },
    });

    const [politicaPrivacidadeResponse, politicaTrocaeDevolucaoResponse, layoutResponse] = await Promise.all([
      politicaPrivacidadePromise,
      politicaTrocaeDevolucaoPromise,
      layoutPromise,
    ]);

    return [politicaPrivacidadeResponse.data, politicaTrocaeDevolucaoResponse.data, layoutResponse.data];
  },

  processar_logo: async (base64: string) => {
    let response = await api.post("upload/uparS3", { base64: base64 });
    return `https://logo.conectavenda.com.br/${response.data}`;
  },

  verificar_primeiro_accesso: async () => {
    try {
      let response = await api.get("configuracoes/verificar_primeiro_acesso");
      response.data =
        response.data === null ? true : response.data == "true" ? true : false;
      return response.data;
    } catch (error) {
      console.log(error);
    }
  },

  verificar_step_criacao: async () => {
    try {
      let response = await api.get("configuracoes/verificar_step_criacao");
      return response.data as {
        empresa: boolean;
        entrega_retirada: boolean;
        pagamento: boolean;
        catalogo: boolean;
        produtos: boolean;
      };
    } catch (error) {
      console.log(error);
    }
  },
  verifica_config_integracoes: async () => {
    try {
      let response = await api.get("configuracoes/verifica_config_integracoes");
      return response.data;
    } catch (error) {
      console.log(error);
    }
  },
});
